import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    button,
    list,
    ratio,
    imageLink,
    image
} from './instagram.module.scss';
import { ISection } from '../../models/section.model';
import { IInstagramPost } from '../../models/instagram-post.model';

import Section from '../hoc/section';

export type IInstagram = ISection & {
    content: {
        images: IInstagramPost[];
    };
};

interface IInstagramProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IInstagram;
}

const Instagram: React.FC<IInstagramProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        css,
        style,
        content: { images, texts, buttons },
    } = section;

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
        >
            <ul className={list}>
                {images.map((instagramPost, index) => {
                    return (
                        <li key={`instagram-post-${index}`}>
                            <a className={imageLink} href={instagramPost.url} target="_blank" rel="noreferrer noopener">
                                <Image className={image} ratioClass={ratio} media={instagramPost.media} />
                            </a>
                        </li>
                    );
                })}
            </ul>
        </Section>
    );
};

export default Instagram;
